.loginBackground {
    background-image: url('../../../asset/img/loginPage/portalone_login_bg.jpg');
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
.loginBackground .footer p {
    left: 0% !important;
    text-align: center;
}