/* ### Admininstration - Customer Account ### */
/* Custom Criteria */
.acc h3 {
  font-size: 16px;
  font-weight: bold;
}
.acc h4 {
  font-size: 14px;
  font-weight: bold;
}
.acc :global(.custom-control-label) {
  font-weight: bold;
  font-size: 14px;
  padding-top: 2px;
}
.accInfo {
  padding: 25px 0px;
}
.accVisit {
  margin: 20px 0px;
}
.accSelect {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.accSelect input {
  width: 4%;
  height: auto;
  margin: 0px 5px;
}
.accAction :global(.custom-checkbox) {
  padding-top: 5px;
}
.accBottom {
  margin-top: 20px;
}

/* ### Admininstration - License ### */
.alRow {
  display: flex;
  margin-bottom: 20px;
}
.alColumn {
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 15px;
}
.alColumn h5 {
  font-size: 16px;
}
.alColumn :global(.form-label) {
  font-size: 14px;
  color: gray;
}
.alRow :global(.config-btn) {
  margin-top: 15px;
}
.alHead {
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 15px;
}
.alHeadChild {
  text-align: center;
  color: gray;
  line-height: 1;
  font-size: 15px;
}
.alHeadChild span {
  font-weight: bold;
}

/* ### Admininstration - Visualize - Banner ### */
.vbCatList {
  margin-top: 25px;
}
.vbCatBox {
  background-color: #f9f6fe;
  padding: 20px 8px;
  border-radius: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.2);
}
.vbCatBox h3 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
}
.vbCatBox p {
  font-size: 12px;
  color: #898889;
  margin-bottom: 0px;
}
.vbCatIcon {
  width: 100% !important;
}
.vbCatActionBox {
  margin-top: -20px;
  margin-bottom: -20px;
  background-color: #fff;
  padding: 0px;
  position: relative;
  right: -7px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid #ddd;
}
.vbCatActionList {
  padding-left: 0px;
  height: 100%;
  list-style: none;
}
.vbCatActionList li {
  padding: 9px 13px 0px 12px;
  border-bottom: 1px solid #ddd;
  height: 50%;
}
.vbCatActionList li:nth-child(2) {
  border-bottom: none;
}
.vbCatActionList li:nth-child(2):hover {
  cursor: pointer !important;
}
.vBanCatInp h2 {
  font-size: 20px;
  padding-bottom: 10px;
}
.vBanCatInp input {
  box-shadow: none !important;
}
.vBanCatInp button {
  box-shadow: none !important;
  border: none !important;
  background-color: #672beb !important;
}
.vbFileUploader {
  width: 100%;
  padding: 5px 0px 15px 0px;
}
.vbCatHeader {
  font-size: 20px;
}
.vbGridImage {
  position: relative !important;
  margin-bottom: 30px !important;
  height: 200px;
  pointer-events: none;
}
.vbGridBanner {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.vbGridCount {
  display: flex;
  background-color: #fff;
  width: 35px !important;
  height: 35px !important;
  position: absolute;
  top: 18px;
  left: 18px;
  border-radius: 20px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}
.vbGridDrag {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 18px;
}
.vbGridDrag img {
  width: 40px;
}
.vbGridDelete {
  cursor: pointer;
  position: absolute;
  bottom: 18px;
  right: 18px;
  border-radius: 30px;
  pointer-events: all !important;
}
.vbGridDelete img {
  width: 40px;
}

/* ### Admininstration - Visualize - Background ### */
.avbAssignModal img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}