.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 20px;
    border-top: 1px solid #ddd;
}
.footer p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.footer span {
    color: #8a2be2;
}
@media only screen and (max-width: 565px) {
    .footer p {
        margin: 0px;
        font-size: 12px;
        text-align: center;
    }
}