body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-bottom: 0 !important;
}

/*##### page content #####*/
/* head */
.contentHead {
  background-color: blueviolet;
  padding: 20px 32px 30px 30px;
  width: 100%;
  height: 170px;
}
.contentHead h1 {
  font-size: 26px;
  color: #fff;
  margin: 0px;
  font-weight: 800;
}

.contentHead ul {
  list-style: none;
  margin: 5px 0px 0px 0px;
}
.contentHead ul li {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}
.contentHead ul li a {
  color: #fff;
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
  border-right: 1px solid #fff !important;
}
.contentHead ul li a:hover {
  text-decoration: none;
}
.contentHead ul li a.noPipe {
  border-right: 0px !important;
}
/* body */
.contentBody {
  background-color: #f6f6f6;
  min-height: 800px;
  padding: 30px;
  padding-bottom: 100px;
}
.contentBody .content {
  margin-top: -110px;
}
.contentBody .contentBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 25px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 18px -2px rgba(0, 0, 0, 0.4);
}

/*##### Forms #####*/
.poDefault button:focus,
button:active:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}
.poForm button {
  background-color: transparent !important;
  border-color: #fff !important;
  border-radius: 50px;
  padding: 3px 35px;
  margin-left: 10px;
  height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 0px;
  font-weight: 500;
}
.config-btn {
  background-color: transparent !important;
  color: blueviolet !important;
  border-color: blueviolet !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  padding: 6px 16px !important;
  margin-right: 10px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}
.config-btn:hover {
  background-color: blueviolet !important;
  color: #fff !important;
  border-color: blueviolet !important;
  box-shadow: none !important;
}
.config-cancel {
  background-color: transparent !important;
  color: rgb(166, 165, 167) !important;
  border-color: rgb(166, 165, 167) !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  padding: 6px 16px !important;
  margin-right: 10px !important;
  border-radius: 50px !important;
  box-shadow: none !important;
}
.config-cancel:hover {
  background-color: rgb(166, 165, 167) !important;
  color: #fff !important;
  border-color: rgb(166, 165, 167) !important;
  box-shadow: none !important;
}
.poLabel {
  font-size: 14px;
  color: #6d6d6d;
  margin: 0;
}

/*##### Flex box #####*/
.poFlex {
  display: flex;
}

.poFlexEnd {
  justify-content: flex-end;
}

/*##### Table #####*/
.poTable table {
  border: 1px solid #dee2e6 !important;
}
.poTable table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: 14px;
  font-weight: 700 !important;
  min-width: 140px;
}
.poTable table td,
.poTable table th {
  border: none !important;
  border-top: 1px solid #dee2e6 !important;
}
.poTable table td {
  font-size: 13px !important;
}
.poTable .potableHeadTitle {
  font-size: 16px;
  font-weight: 600;
}
.poTable .poTableMsg {
  text-align: center;
}
.poTable .poTableOnLoad {
  opacity: 0.4;
  pointer-events: none;
}
.poTable .poTableSelectAll {
  padding-top: 2px;
  padding-right: 10px;
}
.poTable .poTableFetcher {
  position: absolute;
  margin-top: 45px;
  color: #8a2be2;
}
.poTableFilter .form-control {
  margin-right: 7px;
  margin-bottom: 15px;
  border-radius: 50px;
}
.poTable .poTableAction img:first-child {
  margin-right: 25px;
}
.poTable .poTableIcon img {
  padding-left: 10px;
}
.poTable .pagination .page-link {
  color: #8a2be2 !important;
}
.poTable .custom-control-label {
  padding-top: 2px !important;
}
.poTable .pagination .page-item.active .page-link {
  color: #fff !important ;
  background-color: #8a2be2 !important;
  border-color: #8a2be2 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #8a2be2;
  border-color: #8a2be2;
}
.poTable table td p {
  font-size: 13px;
  font-weight: 600;
}

/*##### Toggle Switch #####*/
.poSwitch
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before,
.poSwitch .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: blueviolet;
  background-color: blueviolet;
  width: 33px;
  height: 20px;
  border-radius: 26px;
}
.poSwitch
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  width: 18px;
  height: 18px;
  top: 5px;
}
.poSwitch .custom-switch .custom-control-label::before {
  width: 38px;
  height: 21px;
  top: 4px;
  border-radius: 24px;
}
.poSwitch .custom-switch .custom-control-label::after {
  width: 18px;
  height: 17px;
  top: 6px;
}

/*##### Timeline #####*/
.poTimeline {
  position: relative;
  margin: 35px 0 30px 0;
  padding: 0;
  list-style: none;
}
.poTimeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 22px;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 2px;
}
.poTimeline > li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 75px;
}
.poTimeline > li:before,
.poTimeline > li:after {
  content: " ";
  display: table;
}
.poTimeline > li:after {
  clear: both;
}
.poTimeline > li > .poTimelineItem {
  position: relative;
  margin-left: 75px;
}
.poTimeline > li > .poTimelineItem.tlOne {
  margin-top: 15px;
}
.poTimeline > li > .poTimelineItem.tlOne h3 {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
.poTimeline > li > .poTimelineItem.tlThree {
  margin-top: -8px;
}
.poTimelineItem.tlThree h3 {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
.poTimelineItem.tlThree h4 {
  font-size: 14px;
  color: #7b7b7b;
}
.poTimelineItem.tlThree h5 {
  font-size: 13px;
  color: #000000;
}
.poTimeline > li.tlDate i {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 7px;
  border-radius: 50%;
  top: 0;
}
.poTimeline > li.tlActivity i {
  width: 45px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
  left: 11px;
  top: 0;
}

/* Date */
.poTimeline > li.tlDate i {
  line-height: 18px;
  text-align: center;
  color: #fff;
  background: #8a2be2;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  padding-top: 6px;
}
.poTimeline > li.tlDate i span {
  font-size: 22px;
}

/* Connect */
.poTimeline > li.tlConnect i {
  color: #fff;
  background: #64b4f4;
}

/* Signup */
.poTimeline > li.tlSignUp i {
  color: #fff;
  background: #7ed848;
}

/* OTP */
.poTimeline > li.tlOtp i {
  color: #fff;
  background: #f4ad64;
}

/* Visit */
.poTimeline > li.tlVisit i {
  color: #fff;
  background: #6480f4;
}

/* Email */
.poTimeline > li.tlEmail i {
  color: #fff;
  background: #e57171;
}

/*##### Datepicker Override #####*/
.CalendarDay__selected_span {
  background: #d2acff;
  border: 1px double #fff;
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #8943f2;
  border: 1px double #fff;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #6b2dec;
  border: 1px double #fff;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:active {
  background: #d2acff;
  border: 1px double #fff;
  color: #fff;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}

/*##### Datepicker Override #####*/
.poTabs button {
  background-color: transparent !important;
  border-color: #fff;
  border-radius: 50px;
  padding: 3px 15px;
  margin-left: 10px;
  box-shadow: none;
  height: 35px;
  font-size: 13px;
  margin-top: 0px;
  font-weight: 500;
}

.poTabs button.inActive {
  border: none !important;
}

.poTabs :global(.col-form-label) {
  text-align: right;
  color: #000;
  font-weight: 500;
}
.col-form-label {
  text-align: right;
}
.poTabs .form-control,
.poTabs textarea,
.poTabs select {
  font-size: 14px;
  font-weight: 500;
}
.poTabs input:focus,
.poTabs .form-control:focus,
.poTabs textarea:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #ced4da;
}

/* ### Status Bar ### */
.po-status-bar {
  text-align: center;
  padding: 50px;
}
.po-status-bar p {
  font-size: 16px;
  color: #272637;
  padding-top: 10px;
}

/* ### Label ### */
.poStatus {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.poLabelSuccess {
  background-color: #00a65a !important;
}
.poLabelWarning {
  background-color: #f39c12 !important;
}
.poLabelDanger {
  background-color: #dd4b39 !important;
}
.poLabelInfo {
  background-color: #00c0ef !important;
}

/*##### Add and Remove #####*/
.poAddLabel {
  font-size: 14px;
  color: #6b2dec;
  cursor: pointer;
  text-decoration: underline;
}
.poRemoveLabel {
  font-size: 14px;
  color: #6b2dec;
  cursor: pointer;
  text-decoration: underline;
}

/*##### sortable #####*/
.poSortableGrid {
  display: flex;
  flex-wrap: wrap;
}

/*##### filter #####*/
.poModalFilter .form-control {
  margin-bottom: 15px;
}
.poModalFilter .poModalDateInput {
  display: flex;
  margin-bottom: 15px;
}
.poModalFilter .startDate {
  margin-right: 5px;
}
.poModalFilter .action {
  display: flex;
  justify-content: space-around;
}
.poModalFilter .action button {
  margin-right: 0px !important;
}
.poModalDateLabel {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #6d6d6d;
  margin: 0;
}
.poModalDateInput {
  display: flex;
  margin-bottom: 15px;
}
.poModalDateInput .DateInput {
  width: 47% !important;
}
.poModalDateInput .DateInput_input {
  font-size: 16px !important;
  padding: 6px 14px 4px !important;
}

/*##### common styles #####*/
.poc-dh {
  display: none;
}
.poc-db {
  display: block;
}
.poc-tar {
  text-align: right;
}
.poc-tal {
  text-align: left;
}
.poc-cp {
  cursor: pointer;
}
.poc-border {
  border: 1px #f1f1f1 solid;
}
.poc-br-0 {
  border-radius: 0px !important;
}
.poc-none {
  opacity: 0.5;
  pointer-events: none;
}
/* Margin */
.poc-mp-none {
  margin: 0px !important;
  padding: 0px !important;
}
.poc-mt-10 {
  margin-top: 10px;
}
.poc-mt-15 {
  margin-top: 15px;
}
.poc-mt-50 {
  margin-top: 50px;
}
.poc-mt-80 {
  margin-top: 80px;
}
.poc-mb-10 {
  margin-bottom: 10px !important;
}
.poc-mb-15 {
  margin-bottom: 15px !important;
}
.poc-mb-20 {
  margin-bottom: 20px;
}
.poc-mb-25 {
  margin-bottom: 25px;
}
.poc-ml-10 {
  margin-left: 10px;
}
.poc-mr-20 {
  margin-right: 20px;
}
/* Padding */
.poc-p-15 {
  padding: 15px;
}
.poc-pl-10 {
  padding-left: 10px;
}

/* font and color */
.error {
  color: red;
  font-size: 14px;
}
.poc-fs-18 {
  font-size: 18px;
}
.poc-color {
  color: #8a2be2 !important;
}
.poc-link {
  color: #6b2dec;
  cursor: pointer;
  text-decoration: underline;
}
.form_header {
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.pad-20 {
  padding: 20px;
}
.bottom-border {
  border-bottom: 1px solid #f1f1f1;
}
.pageContent .form-group {
  margin-bottom: 0;
}

/* image */
.poc-icon-img {
  width: 20px;
}
.poc-tiny-img {
  width: 50px;
}

/* ###### Media Queries ###### */
/* Heder */
@media screen and (max-width: 1024px) and (min-width: 1000px) {
  .contentHead h1 {
    font-size: 20px;
    margin: 3px 0px;
  }
  .contentHead ul li a {
    font-size: 12px;
    padding-right: 10px;
  }
  .contentHead ul li a:nth-child(2) {
    padding-left: 10px;
  }
  .poTabs button {
    padding: 3px 12px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .contentHead h1 {
    font-size: 20px;
    margin: 5px 0px;
  }
  .poTabs button {
    padding: 3px 20px;
  }
}

[data-rbd-drag-handle-context-id="0"] {
  flex: 0 0 33.3% !important;
  max-width: 33.3% !important;
}
