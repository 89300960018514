.header {
    background-color: blueviolet;
    height: 100px;
    width: 100%;
    padding: 30px;
}

/* box */
.header ul {
    list-style: none;
    padding: 0px;
    position: absolute;
    right: -10px;
}
.header ul li {
    display: inline-block;
    border-right: 1px solid #fff;
    padding: 0px 25px 0px 20px;
}
.header ul li:last-child {
    border-right: 0;
}
.header ul li img:last-child {
    margin-left: 15px;
}

.header ul li:last-child .dropdown {
    display: none !important;
}
/* search */
.searchIcon {
    width: 15px;
}
.header :global(.form-control:focus) {
    box-shadow: none !important;
}

.header :global(.input-group) {
    width: 85% !important;
}

.header ::placeholder {
    color: #fff !important;
}
.header :global(.input-group-text) {
    background-color: transparent !important;
    border-color: #fff !important;
    border-right: 0px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.header :global(.form-control) {
    background-color: transparent !important;
    border-color: #fff !important;
    border-left: 0px;
    border-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-left: 0px;
    color: #fff !important;
    padding: 14px 15px 16px 0px;
    font-size: 14px;
}
/* logo */
.logoImg {
    width: 160px;
    margin-right: auto;
    margin-left: auto;
    display: block;
}
/* dropdown */
.dropDown :global(.btn-success.dropdown-toggle) {
    color: #fff !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
    padding: 0px !important;
    font-size: 14px;
    text-transform: none;
}