/* Analytics Head */
.ahFilter {
  display: flex;
  justify-content: flex-end;
}
.ahFilter .current {
  background: #fff !important;
  color: #8a2be2;
  font-weight: bold;
}
.ahFilterForm :global(.form-control) {
  margin-bottom: 15px;
}
.ahFilterForm .aoDateSelect {
  display: flex;
  margin-bottom: 15px;
}
.ahFilterForm .startDate {
  margin-right: 5px;
}
.ahFilterForm .action {
  display: flex;
  justify-content: space-around;
}
.ahFilterForm .action button {
  margin-right: 0px !important;
}
.ahForm button {
  background-color: transparent !important;
  border-color: #fff;
  border-radius: 50px;
  padding: 3px 35px;
  box-shadow: none;
  height: 35px;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 0px;
  font-weight: 500;
}

/*##### Overview Page style #####*/
.aoSmallBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px !important;
}
.aoMediumBox {
  height: 430px !important;
}
.aoMediumLgBox {
  height: 467px !important;
}

/* box content common */
.aoPageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}
.abHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.abHeadRight {
  display: flex;
}
.abHeadRight img {
  padding-right: 10px;
}
.abForm select {
  font-size: 14px;
  border-radius: 50px;
}
.abIconUp {
  color: #8ecd40;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
}
.abIconDown {
  color: #e04742;
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
}
.abIconUp span,
.abIconDown span {
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 400 !important;
}
.abIconUp img,
.abIconDown img {
  margin-right: 5px;
}

/* small box content */
.absContent {
  display: flex;
  padding-top: 10px;
}
.absContent img {
  width: 100%;
}
.absContent h1 {
  font-size: 30px;
  font-weight: bold;
  color: #2d008e;
}
.absContent p {
  font-size: 16px;
  color: #4e2f90;
}

/* medium box content */
.abmContent {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
.abmContent h1 {
  font-size: 32px;
  font-weight: bold;
  color: #2d008e;
}
.abmBottom {
  padding-top: 25px;
  text-align: center;
}

/* medium large box content */
.abmlContent {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.abmlContent img {
  width: 50px;
  height: auto;
}
.abmlContent h1 {
  font-size: 30px;
  font-weight: bold;
  color: #2d008e;
  padding-left: 12px;
}
.abmlContent span {
  font-size: 22px;
  padding-top: 0px;
  font-weight: 100;
  padding-left: 10px;
}

/* unique box */
.abVisual {
  /* background-image: url(../../img/analytics/visual_merchandising_bg.png); */
  background-repeat: no-repeat;
  background-position: bottom;
}
.abVisual .abmContent img {
  width: 72px;
}
.abVisual h1 {
  padding-top: 18px;
  padding-left: 14px;
}
.abVisual .icon-img {
  width: 100%;
}
.abUr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.abUrContent {
  text-align: center;
}
.abUrContent p {
  padding-top: 10px;
}
.abBc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.abAts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.abAts .hrs {
  font-size: 22px;
  padding-top: 0px;
  font-weight: 100;
  padding-left: 10px;
  padding-left: 10px;
  color: #c1c0c0;
  font-weight: bold;
}
.abAtsBottom div {
  display: flex;
  justify-content: space-between;
  background-color: #faf7ff;
  padding: 14px;
  margin-bottom: 10px;
}
.abAtsBottom span {
  color: #2d008e;
}
/* date picker */
.aoDateSelect :global(.DateInput) {
  width: 47% !important;
}

.aoDateSelect :global(.DateInput_input) {
  font-size: 16px !important;
  padding: 6px 14px 4px !important;
}

/*##### Customer Page style #####*/
.acPageBody {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  min-height: 130px !important;
}
.acProfileBox {
  margin: 0px 20px;
}
.acProfileBanner {
  width: 100%;
  height: 180px;
  margin: 0px !important;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}
.acProfile {
  display: flex;
  justify-content: space-between;
}
.acProfile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.acProfile span {
  font-size: 14px;
  align-self: flex-end;
}
.acProInfo {
  display: flex;
  justify-content: space-between;
}
.acProInfo button {
  height: 34px !important;
}
.acProInfo h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
}
.acProInfo span {
  font-size: 14px;
}
.acProDetails h3 {
  font-size: 16px;
  color: #959ea5;
}
.acProDetails p {
  font-size: 16px;
}
.acMore {
  font-size: 13px;
  font-weight: bold;
  color: #913ae4;
}
.acWifiBox .headTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
}
/* tabs */
.acTabs :global(.nav-tabs .nav-link) {
  border: none;
  border-bottom: 2px solid #e6e6e6;
}
.acTabs :global(a.active) {
  color: #8a2be2 !important;
  border: none;
  border-bottom: 2px solid #8a2be2 !important;
}
.acTabs :global(a) {
  color: #000;
}
.acTabs :global(a:hover),
:global(a:active) {
  outline: 0 !important;
  box-shadow: none !important;
  color: #8a2be2 !important;
  border: none;
}
.acLoading {
  text-align: center;
  padding-top: 20px;
  color: #8a2be2;
}
/*##### Zone Page style #####*/
.azLoading {
  text-align: center;
  padding-top: 20px;
  color: #8a2be2;
}
/* analysis */
.azAnaBody {
  padding: 15px;
}
.azAnaDist {
  padding: 15px;
  background-color: #f0f0f0;
}
.azAnaDist :global(.row) {
  margin: 10px 0 0 0;
  min-height: 200px;
}
.azAnaHw {
  min-height: 200px;
  background-color: #6b2dec;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.azAnaWd {
  min-height: 200px;
  /* background-color: #8943f2; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.azAnaMF {
  min-height: 200px;
}
.azAnaMd {
  height: 70%;
  padding: 0 0px 5px 5px;
}
.azAnaMd div {
  height: 100%;
  background-color: #ae6bff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.azAnaFr {
  height: 30%;
  padding-left: 5px;
}
.azAnaFr div {
  height: 100%;
  background-color: #d2acff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.azAnaDist h1 {
  font-size: 28px;
  color: #fff;
}
.azAnaDist h2 {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.azAnaDist h2 span {
  font-size: 18px;
  font-weight: 100;
  vertical-align: middle;
  color: #f0f0f0;
}
.azAnaDist h5 {
  color: #fff;
}
.azAnaTable {
  font-size: 14px;
}
/* Overview */
.azOv img {
  width: 100%;
}
.azOvBg {
  background-color: #faf8ff;
  border-radius: 10px;
  padding: 20px;
}
.azOvList {
  /* padding: 15px; */
  padding: 0;
}
.azOvList li {
  list-style: none;
  background-color: #fff;
  border-radius: 60px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
}
/* .azOvList :global(.col-sm-9) {
  padding: 0px;
} */
.azOvList p {
  color: #6f64eb !important;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.azOvList h1 {
  color: #6f64eb;
  font-size: 22px;
}
.azOvMin {
  font-size: 18px;
}
.azOvDown {
  background-color: #e04642;
  border-radius: 50px;
  padding: 5px 13px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  top: -1px;
  position: relative;
}
span.azOvDown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 9px solid white;
  top: 15px;
  position: relative;
  left: -3px;
}
.azOvUp {
  background-color: #8ecd40;
  border-radius: 50px;
  padding: 5px 13px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  top: -1px;
  position: relative;
}
span.azOvUp:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 9px solid white;
  top: -15px;
  position: relative;
  left: -3px;
}
.azOvWhiteBg {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
.azOvDraw {
  padding-top: 4%;
}
.azOvP {
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 12px;
}
.azOvH {
  font-size: 24px;
}
.azOvGreenBorder {
  width: 85px;
  height: 85px;
  display: inline-block;
  border: 3px solid #8ecd40;
  border-radius: 50px;
  padding: 16px 30px 9px 31px;
  margin: 10px auto 0 30px;
  background-color: #fff;
  position: relative;
  z-index: 99;
}
.azOvBlueBorder {
  border: 2px dashed #3ba9f4;
  width: 97%;
  position: relative;
  left: -5.5%;
}
.azOvSecondBlue::before {
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  border-left: 4px dashed #3ba9f4;
  height: 50px;
}
.azOvRedBorder {
  border: 3px solid #e04642;
}
.azOvImgBorder {
  width: 100%;
}
.azOvEngageGreenBorder {
  padding: 14px 23px 8px 21px;
}
.azOvBt::before {
  content: "";
  position: absolute;
  top: -50px;
  bottom: 0;
  left: 57%;
  border-left: 4px dashed #8ecd40;
  height: 230px;
}
.azOvBt::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #8ecd40;
  position: absolute;
  top: -70px;
  left: 55%;
  z-index: 999;
}
.azOvBtg {
  border: 4px dashed #8ecd40;
  padding: 15px;
  /* flex: 8 0 56%;
  max-width: 66%; */
}
.azOvBgEm {
  background-color: #faf8ff;
  width: 62px;
  height: 20px;
  position: relative;
  top: -28px;
  left: 40%;
  margin-bottom: -14px;
}
.azOvBgEm::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-right: 11px solid #8ecd40;
  border-bottom: 12px solid transparent;
  position: absolute;
  left: 51px;
}
.azOvBgEm::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 11px solid #8ecd40;
  border-bottom: 12px solid transparent;
  position: absolute;
}
.azOvBgEmCont {
  position: relative;
  right: 29%;
  text-align: center;
}
.azOvMt11 {
  margin-top: 90px;
}
.azOvMt49 {
  margin-top: 49%;
}
.azOvVertRedBr {
  position: relative;
  /* left: -5%; */
}
.azOvVertRedBr::before {
  content: "";
  position: absolute;
  top: -50px;
  bottom: 0;
  left: 75%;
  border-left: 4px dashed #e04642;
  height: 430px;
}
.azOvRedBorderHr {
  border: 2px dashed #e04642;
  position: relative;
  top: -50px;
  right: 93%;
  width: 168%;
}
.azOvVertRedBr::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #e04642;
  position: absolute;
  bottom: -55px;
  right: 20%;
}
.azOvMinRed {
  background-color: #ffffff;
  margin-top: 40px;
  padding: 5px;
  text-align: center;
  color: #e04642;
  font-size: 12px;
  position: relative;
  left: 20px;
}
.azOvBlBord {
  border: 4px dashed #3ba9f4;
  padding: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.azOvVertBlueBr::before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 20%;
  border-left: 4px dashed #3ba9f4;
  height: 370px;
}
.azOvBlBord::before {
  content: "";
  position: absolute;
  top: -4px;
  bottom: 0;
  left: -20%;
  border-top: 4px dashed #3ba9f4;
  width: 20%;
}
.azOvBlBord::after {
  content: "";
  position: absolute;
  top: -106px;
  bottom: 0;
  left: -4px;
  border-right: 4px dashed #3ba9f4;
  height: 100px;
}
.azOvVertBlueBr::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #3ba9f4;
  position: absolute;
  bottom: -55px;
  left: 18.5%;
}
.azOvMinBlue {
  background-color: #fff;
  margin-top: 100px;
  padding: 5px;
  text-align: center;
  color: #3ba9f4;
  font-size: 12px;
}
.azOvBotton {
  text-align: center;
}
.azOvImg {
  width: 85px;
  height: 85px;
  border-radius: 50px;
  margin-top: 5px;
  background-color: #fff;
  position: relative;
  z-index: 99;
}
.azOvBorderBrown {
  border: 3px solid #ec9755;
  padding: 21px 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.azOvTwoP {
  padding: 14px 30px;
  margin-left: 38%;
  margin-bottom: 25px;
  margin-top: -23px;
}
.azOvThP {
  padding: 19px 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}
.azOvBotton {
  position: relative;
  border-top: 2px dashed #bcbec0;
  width: 100%;
  top: 150px;
}
.azOvBotton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 11px solid #bcbec0;
  border-bottom: 12px solid transparent;
  position: absolute;
  right: -7px;
  top: -13px;
}
.azOvBottonSec {
  margin-top: 100px;
}
.azOvBr::after {
  content: "";
  position: absolute;
  top: -262px;
  bottom: 0;
  left: 50%;
  border-left: 4px dashed #8ecd40;
  height: 285px;
}
.azOvBr::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #8ecd40;
  position: relative;
  top: -308px;
  left: 4px;
}
.ml-0 {
  margin-left: 0;
}


/* Media Query */
@media screen and (max-width: 992px) and (min-width: 760px) {
  .abVisual .abmContent img {
      width: auto;
      height: 35px;
  }
  .abVisual h1 {
      padding-top: 0px;
  }
  .aoPageBody {
      min-height: 206px;
  }
  .absContent h1 {
      font-size: 25px;
  }
  .absContent p {
      margin-bottom: 0px;
  }
  .abmlContent {
      display: block;
      text-align: center;
  }
  .abmlContent h1 {
      font-size: 25px;
      margin-top: 6px;
      padding-left: 0px;
  }
  .abmlContent span {
      font-size: 16px;
      padding-left: 7px;
      top: -2px;
      position: relative;
  }
  .abAtsBottom span {
      font-size: 14px;
  }
  .abUrContent p {
      font-size: 12px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 1000px) {
  .analytisTabs :global(#left-tabs-example-tabpane-zone) p {
      font-size: 10px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) h1 {
      font-size: 25px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) h1>span {
      font-size: 20px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) h1>span:nth-child(2) {
      font-size: 14px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) .col-sm-3 ul li .col-sm-3 img {
      width: 35px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) .col-sm-3 ul li {
      border-radius: 10px;
  }
  .analytisTabs :global(#left-tabs-example-tabpane-zone) .col-sm-3 ul li .col-sm-3 img {
      width: 35px;
  }
}

@media screen and (min-width: 1920px) {
  .azOvVertRedBr::after {
    right: 21.5%;
  }
}